import Loading from "components/Loading";
import React, { Suspense, lazy } from "react";
import { HashRouter, Routes, Route ,BrowserRouter} from "react-router-dom";

const HomePage = lazy(() => import('pages/home'));
const ProjectPage = lazy(() => import('pages/projects'));
const Layout = lazy(() => import('pages/layout'));
const ProjectDetails = lazy(() => import('pages/projects/details'))
const ContactUs = lazy(() => import('pages/contactus'))
const Imprint = lazy(() => import('pages/imprint'))
const About = lazy(() => import('pages/about'))
const Team = lazy(() => import('pages/team'))

function Authentication() {
    return (
        <Suspense fallback={<Loading />}>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route path="projects" element={<ProjectPage />} />
                        <Route path="projects/:id" element={<ProjectDetails />} />
                        <Route path="contact" element={<ContactUs />} />
                        <Route path="imprint" element={<Imprint />} />
                        <Route path="about" element={<About />} />
                        <Route path="team" element={<Team />} />
                    </Route>
                </Routes>
            </HashRouter>
        </Suspense>
    );
}

export default Authentication;