import RootNav from "navigations";
import 'assets/styles/scss/style.scss';
import "assets/styles/globals.css";

import "assets/styles/css/plugins/bootstrap-grid.css";
import "assets/styles/css/plugins/swiper.min.css";
import "assets/styles/css/plugins/magnific-popup.css";

function App() {
  return (
    <RootNav/>
  );
}

export default App;
